/* Fonts */
@font-face {
  font-family: "DIN";
  src: url(../public/Fonts/DIN/ArbFONTS-DINNextLTArabic-Regular-4.ttf);
}

/* Global rules */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DIN";
}

::-webkit-scrollbar {
  width: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Variables */

:root {
  --bg-gradient-darkBlue: linear-gradient(180deg, #3c489e 0%, #0c1d32 100%);
  --bg-gradient-darkTransparent: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0) 0%,
    rgba(6, 6, 6, 0.5) 96.77%
  );
  --bg-gradient-darkBlueBlack: linear-gradient(
    180deg,
    rgba(24, 59, 104, 0.23) 0%,
    rgba(41, 49, 59, 0.23) 100%
  );
  --pink-color: #b03f6e;
  --blue-color: #3c489e;
  --navMMenu-background: #f5f7fe;
  --navMMenuLink-background: #cfd9fc;
  --navMMenuLinkActive-background: #3c489e;
}

.Rectangle {
  width: 40%;
  z-index: -1;
}

.Rectangle1 {
  top: -6vh;
  right: 0;
}
.Rectangle2 {
  top: 75vh;
  left: -100px;
}
.Rectangle3 {
  top: 215vh;
  right: 0;
}
.Rectangle4 {
  top: 300vh;
  left: 0;
}

/* Custom classes */

.bg-gradient-darkBlue {
  background: var(--bg-gradient-darkBlue);
}
.bg-gradient-darkTransparent {
  background: var(--bg-gradient-darkTransparent);
}
.bg-gradient-darkBlueBlack {
  background: var(--bg-gradient-darkBlueBlack);
}

.pink-color {
  color: var(--pink-color);
}

.blue-color {
  color: var(--blue-color);
}

.navMMenu-background {
  background-color: var(--navMMenu-background);
}

.navMMenuLink-background {
  background-color: var(--navMMenuLink-background);
}

.mainSectionheight {
  height: calc(100vh - 48px);
}

.pink-Nav-link,
.blue-Nav-link {
  font-size: calc(0.6rem + 0.4vw);
  box-shadow: 0 0 1px black;
}
.pink-Nav-link {
  background-color: var(--pink-color);
}

.blue-Nav-link {
  background-color: var(--blue-color);
}

@media screen and (max-width: 992px) {
  .mainSectionheight {
    height: 100vh;
  }
}

.hero {
  min-height: 232px;
  height: 50vw !important;
  max-height: 75vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center center;
}

.hero::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--bg-gradient-darkTransparent);
  top: 0;
  left: 0;
  z-index: 0;
}

.heroContent {
  color: white !important;
  padding: 20px;
}

.heroContent p {
  font-size: calc(0.6rem + 0.8vw);
}

.heroContent h1 {
  font-weight: bold;
  font-size: calc(0.6rem + 1.2vw);
}

.custom-container {
  margin: auto;
  padding: 0 20px;
}

section {
  margin-block: 5vw !important;
}

.pattern3 {
  align-items: center;
}

.pattern-text {
  width: 600px;
}

.pattern-images {
  width: 100%;
  max-width: 550px;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.pattern-images-group {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 80px;
}

.pattern-images-group img {
  width: 350px;
  max-width: 350px;
  display: block;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .pattern-images {
    height: 60vh;
  }
  .pattern-images-group img {
    width: 250px;
    max-width: 250px;
  }
}

.pattern-image1 {
  top: 0;
  right: -5px;
}
.pattern-image2 {
  top: -37.5px;

  right: 75px;
}
.pattern-image3 {
  top: -85px;
  right: 0px;
}

@media screen and (max-width: 768px) {
  .pattern3 {
    flex-direction: column;
    align-items: center;
  }
  .pattern-text,
  .pattern-images {
    width: 100%;
    text-align: center;
  }
  .pattern-images {
    display: flex;
    justify-content: center;
  }
  .pattern-images-group {
    right: initial;
  }
}

/* Containers */

@media screen and (max-width: 1920px) {
  .custom-container {
    width: 1080px !important;
  }
}

@media screen and (max-width: 1200px) {
  .custom-container {
    max-width: 992px;
  }
}

@media screen and (max-width: 992px) {
  .custom-container {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .custom-container {
    max-width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .custom-container {
    max-width: 100%;
  }
}

/***************** Navbar Component Style  ****************/

.menuNavOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: 10;
  transition: 0.2s all ease-in-out;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}

.menuNavOverlay.active {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

nav {
  box-shadow: 10px 0 5px black;
  position: relative;
  z-index: 999 !important;
  background-color: var(--navMMenu-background) !important;
}
.logo {
  width: 75px;
}

.arabicLanguageFlag,
.englishLanguageFlag {
  width: 30px;
}

.burger .line1,
.burger .line2,
.burger .line3 {
  width: 1.8rem;
  height: 0.2rem;
  background-color: black;
}

.navbar .burger {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .navbar .list {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    background-color: var(--navMMenu-background);
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transition: 0.2s all ease-in-out;
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    z-index: 11;
  }
  .navbar .list.list-active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  .close {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .close .line1,
  .close .line2 {
    width: 1.8rem;
    height: 0.2rem;
    background-color: black;
    position: absolute;
  }

  .close .line1 {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }

  .close .line2 {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }

  .navbar .list ul {
    display: flex;
    flex-direction: column;
    margin-left: 0 !important;
    gap: 15px;
  }

  .navbar .list .nav-item .nav-link {
    background-color: var(--navMMenuLink-background);
    padding: 10px 15px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding-inline: 25px;
    width: fit-content;
    color: white;
  }

  .navbar .list .nav-item .nav-link.active {
    background-color: var(--navMMenuLinkActive-background);
  }
  .navbar .burger {
    display: flex;
  }
}
/***************** NotFound Component Style  ****************/

.notFountHeaderContent h1 {
  font-size: calc(3rem + 2vw);
  font-weight: bold;
}

.notFountHeaderContent p {
  font-size: calc(0.8rem + 0.6vw);
}

.notFoundBackImage {
  width: 100%;
  opacity: 0.7;
  height: 100%;
  background-image: url(assets/images/Papers.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

@media screen and (max-width: 840px) {
  .notFoundBackImage {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: initial;
  }
}

/***************** Footer Component Style  ****************/

footer {
  position: relative;
  z-index: 10;
}

.footerContent {
  border-bottom: 2px solid white;
  gap: 20px;
  font-size: calc(0.5rem + 0.6vw);
}

.footerColumn:first-of-type {
  width: calc(35% - 30px);
  margin-right: 40px;
}

.footerColumn:nth-of-type(2) {
  width: calc(15% - 30px);
}

.footerColumn:nth-of-type(3) {
  width: calc(20% - 30px);
}

.footerColumn:last-of-type {
  width: calc(30% - 30px);
}

@media screen and (max-width: 1080px) {
  .footerColumn:first-of-type {
    width: 100%;
  }
  .footerColumn:nth-of-type(2),
  .footerColumn:nth-of-type(3),
  .footerColumn:nth-of-type(4) {
    width: calc(100% / 3 - calc(40px / 3));
  }
}

@media screen and (max-width: 840px) {
  .footerContent {
    gap: 25px;
  }

  .footerColumn:nth-of-type(1),
  .footerColumn:nth-of-type(2),
  .footerColumn:nth-of-type(3),
  .footerColumn:nth-of-type(4) {
    width: 100%;
    margin: 0;
  }
}

.footerColumnTitle h3 {
  font-size: 18px;
  font-weight: bold;
}

.footerColumn .companyDescription {
  font-size: 14px;
  text-align: justify;
}

.footerColumn ul li:not(:last-of-type) {
  margin-bottom: 7.5px;
}

/***************** Home Component Style  ****************/

/* hero */
.homePage .hero {
  background-image: url("../src/assets/images/Pages/Home/homepage-hero.jpg");
}

/* experience */

@media screen and (max-width: 768px) {
  .experience h2 {
    font-size: 1.5rem;
  }
  .experience p {
    font-size: 0.88rem;
  }
}

/* chooose */
.choose {
  background-color: var(--navMMenuLinkActive-background);
}

.choose-question {
  width: 30%;
}

.choose-columns {
  width: 70%;
  gap: 15px;
}

.choose-columns .choose-column {
  display: flex;
  align-items: center;
  height: 100%;
}

.choose-columns .choose-column:not(:nth-of-type(2)) {
  width: calc(75% / 2);
}

.choose-columns .choose-column:nth-of-type(2) {
  width: 25%;
}

@media screen and (max-width: 992px) {
  .choose {
    display: none !important;
  }
}

/* products */

.products-columns {
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.products-column {
  background-color: var(--navMMenuLinkActive-background);
  width: 300px !important;
}

.products-column h3 {
  font-size: 1rem;
}

.products-column p {
  font-size: calc(0.6rem + 0.4vw);
}

@media screen and (max-width: 768px) {
  .Products-title h2 {
    font-size: 1.5rem;
  }
}

/* testmonails */

.react-multi-carousel-list {
  padding: 45px 0 !important;
}

.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
  position: absolute;
  background-color: var(--navMMenuLinkActive-background) !important;
  opacity: 0.1 !important;
  transition: 0.2 all ease-in-out;
  -webkit-transition: 0.2 all ease-in-out;
  -moz-transition: 0.2 all ease-in-out;
  -ms-transition: 0.2 all ease-in-out;
  -o-transition: 0.2 all ease-in-out;
}

.react-multiple-carousel__arrow--left:hover,
.react-multiple-carousel__arrow--right:hover {
  opacity: 1 !important;
}

.quotes {
  top: -30px;
  padding: 20px;
  background-color: var(--navMMenuLinkActive-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.opinion-box {
  margin-inline-end: 15px;
}

.opinion-box .opinion {
  font-size: calc(0.4rem + 0.6vw);
}

.personal-image-name {
  border-top: 2px solid var(--navMMenu-background) !important;
}

.personal-image-name .personal-image img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
/* we do */

.weDo {
  background-color: var(--navMMenuLinkActive-background);
}

.weDo h2 {
  font-size: calc(1rem + 0.6vw) !important;
}

.doingList p {
  padding-inline: 2vw;
  font-size: calc(0.5rem + 0.5vw) !important;
}
.doingList p:not(:last-of-type) {
  border-right: 2px solid white;
}

@media screen and (max-width: 768px) {
  .doingList {
    flex-direction: column;
    align-items: center;
  }
  .doingList p {
    padding-inline: 0;
    font-size: calc(0.6rem + 0.5vw) !important;
  }
  .doingList p:not(:last-of-type) {
    border-right: none !important;
  }
}

/***************** About Component Style  ****************/

.aboutPage .hero {
  background-image: url("../src/assets/images/Pages/About/aboutpage-hero.webp");
}

.presidentWord,
.workersWord,
.differWord {
  width: 475px;
}

.presidentWord h2,
.workersWord h2,
.differWord h2 {
  font-size: calc(1.2rem + 0.5vw);
}
.presidentWord p,
.workersWord p,
.differWord p {
  font-size: calc(0.5rem + 0.5vw);
}

.presidentImage img,
.workersImage img,
.differImage img {
  width: 475px;
  border-radius: 48px;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  -ms-border-radius: 48px;
  -o-border-radius: 48px;
}

@media screen and (max-width: 1280px) {
  .presidentWord,
  .workersWord,
  .differWord {
    width: 425px;
  }

  .presidentImage img,
  .workersImage img,
  .differImage img {
    width: 425px;
  }
}

@media screen and (max-width: 992px) {
  .president,
  .workers,
  .differ {
    flex-direction: column;
  }

  .presidentWord p,
  .workersWord p {
    font-size: calc(0.8rem + 0.5vw);
  }

  .presidentImage,
  .workersImage,
  .differImage {
    order: -1;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .presidentImage img,
  .workersImage img,
  .differImage img {
    width: 80%;
    margin: auto;
    max-width: 500px;
  }

  .presidentWord,
  .workersWord {
    text-align: center;
    width: 100%;
  }
  .differWord {
    width: 100%;
  }

  .differWord h2 {
    text-align: center;
  }
}

.differWord ul {
  list-style: disc;
}

/* purpose */

.purpose {
  background-image: url("../src/assets/images/Pages/About/purpose.webp");
  background-size: cover;
  background-position: center 75%;
  background-repeat: no-repeat;
  position: relative;
  padding: 32px;
}

.purpose::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: var(--bg-gradient-darkBlue);
  top: 0;
  left: 0;
  opacity: 0.75;
}

.purposeContent {
  position: relative;
  z-index: 10;
}

.choose-grid-columns {
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.choose-grid-item {
  background-color: var(--blue-color) !important;
  display: flex;
  align-items: center;
}

.choose-grid-item {
  height: 25vh;
}

.choose-grid-column {
  padding: 5px !important;
}

/***************** Services Component Style  ****************/

.servicesPage .hero {
  background-image: url("../src/assets/images/Pages/Services/images/servicespage-hero.png");
}

.service-discription-content,
.blogs-discription-content {
  text-align: justify;
}

.services-discription-content,
.service-discription-content,
.blogs-discription-content {
  width: 75%;
}

@media screen and (max-width: 992px) {
  .services-discription-content,
  .service-discription-content,
  .blogs-discription-content {
    width: 100%;
  }
}

.services-discription-content p,
.service-discription-content p,
.blogs-discription-content p {
  font-size: calc(0.6rem + 0.8vw);
}

.services-blocks {
  gap: 20px;
}

.services-block {
  width: calc(1060px / 2 - 20px);
  height: 40vh;
  position: relative;
  overflow: hidden;
  background-color: #d9d9d9;
}

.services-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--navMMenuLinkActive-background);
  opacity: 0.5;
}

.services-block::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  opacity: 1;

  background-image: url("../src/assets/images/Pages/Services/shapes/service-block-blue-shape.svg");

  z-index: 2;
}

.services-block1,
.services-block2,
.services-block3,
.services-block4 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@media screen and (max-width: 1200px) {
  .services-block {
    width: calc(972px / 2 - 20px);
  }
}

@media screen and (max-width: 992px) {
  .services-block {
    width: calc(748px / 2 - 20px);
  }
}

@media screen and (max-width: 768px) {
  .services-block {
    width: calc(100%);
  }
}

.services-block1 {
  background-image: url("../src/assets/images/Pages/Services/images/corporate-insurance.jpeg");
}
.services-block2 {
  background-image: url("../src/assets/images/Pages/Services/images/occupational-hazards.jpg");
}
.services-block3 {
  background-image: url("../src/assets/images/Pages/Services/images/advice-on-risk-management.jpg");
}
.services-block4 {
  background-image: url("../src/assets/images/Pages/Services/images/advice-on-claims.jpg");
}

.service-block-content {
  z-index: 3;
  bottom: -20px;
}

.service-block-content h3 {
  font-size: calc(0.88rem + 0.5vw);
}

.service-block-content p {
  font-size: calc(0.5rem + 0.5vw);
}

/* Corporate Service */

.corporateServicesPage .hero {
  background-image: url("../src/assets/images/Pages/Services/corporate/images/corporate-hero.jpg");
}

/* Occupational Service */

.occupationalServicesPage .hero {
  background-image: url("../src/assets/images/Pages/Services/occupational/images/occupational-hero.jpg");
}

.service-block {
  gap: 50px;
}

.service-block-list,
.service-block-image {
  width: calc(1000px / 2 - 50px);
}

.service-block-list {
  list-style: disc;
  list-style-position: inside;
}

.service-block-image {
  height: 50vh;
  overflow: hidden;
}

.service-block-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.benefits-grid-column-item {
  background-color: var(--blue-color);
  display: flex;
  align-items: center;
  height: 25vh;
}

@media screen and (max-width: 768px) {
  .benefits-grid-column {
    width: 80% !important;
    text-align: center;
  }
  .benefits-grid-column-item {
    height: 17.5vh;
  }
}

.benefits-grid-column p {
  font-size: calc(0.6rem + 0.4vw);
}

@media screen and (max-width: 768px) {
  .benefits-grid-title h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .service-block {
    gap: 50px;
  }
  .service-block-list,
  .service-block-image {
    width: calc(768px / 2 - 50px);
  }
}
@media screen and (max-width: 768px) {
  .service-block {
    gap: 50px;
  }
  .service-block-list,
  .service-block-image {
    width: 100% !important;
  }

  .service-block1 .service-block-image,
  .service-block3 .service-block-image {
    order: -1;
  }
}

/* management Services */

.managementServicesPage .hero {
  background-image: url("../src/assets/images/Pages/Services/managments/images/managment-hero.jpg");
}

.management-list {
  list-style: disc;
}

.managementServicesPage
  .management-block
  .management-block-body
  .management-block-text {
  width: 400px;
}

.managementServicesPage
  .management-block
  .management-block-body
  .management-block-text
  p {
  font-size: calc(0.6rem + 0.6vw);
}

.managementServicesPage
  .management-block
  .management-block-body
  .management-block-image
  img {
  width: 100%;
  max-width: 400px;
  height: 50vh;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .managementServicesPage .management-block .management-block-body {
    align-items: center;
  }
  .managementServicesPage
    .management-block
    .management-block-body
    .management-block-image,
  .managementServicesPage
    .management-block
    .management-block-body
    .management-block-text {
    width: 100% !important;
  }
  .managementServicesPage
    .management-block
    .management-block-body
    .management-block-image {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

.note-block p {
  max-width: 500px;
  width: 80%;
  margin: auto;
}

.management-grid-columns {
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.management-grid-column {
  background-color: var(--blue-color) !important;
  width: calc(992px / 3 - 50px) !important;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 20vh;
}

.management-grid-column::before {
  content: "";
  position: absolute;
  background-image: url("assets/images/Pages/Services/managments/shapes/pink-shape2.svg");
  bottom: -10%;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.management-grid-column p {
  font-weight: 900;
  font-size: calc(0.6rem + 0.6vw) !important;
  position: relative;
  z-index: 2;
  line-height: 25px;
}

@media screen and (max-width: 768px) {
  .management-grid-column {
    width: 80% !important;
    text-align: center;
  }
}

.management-grid-column p {
  font-size: calc(0.6rem + 0.4vw);
}

@media screen and (max-width: 768px) {
  .management-grid-title h2 {
    font-size: 1.5rem;
  }
}

.management-discription {
  text-align: justify;
}

/* claim Service */

.claimsServicesPage .hero {
  background-image: url("../src/assets/images/Pages/Services/claims/images/claims-hero.jpg");
}

.claims-list {
  list-style-type: disc;
}

.claims-discription {
  width: 100%;
  background-image: url("assets/images/Pages/Services/claims/images/claims-discription.jpeg");
  background-position: center -450px;
  background-repeat: no-repeat;
  position: relative;
  padding: 60px 20px;
  overflow: hidden;
}

.claims-discription::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--bg-gradient-darkBlue);
  top: 0;
  left: 0;
  opacity: 0.6;
}

/***************** Blogs Component Style  ****************/

.blogsPage .hero {
  background-image: url("../src/assets/images/Pages/blogs/blogs-hero.jpg");
}

.blog-pattern {
  gap: 10px;
}

.blogsCatBtns li {
  font-size: calc(0.7rem + 0.5vw);
  margin-block: 5px;
  transition: 0.2s all ease-in-out;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  border-bottom: 2px solid transparent;
  opacity: 1;
  cursor: pointer;
}
/* 
.blogsCatBtns li.active {
  color: black;
  opacity: 1;
  font-weight: bold;
  border-bottom: 2px solid black;
} */

.blog-column {
  width: calc(100% / 3 - calc(60px / 3));
}

@media screen and (max-width: 992px) {
  .blog-column {
    width: calc(100% / 2 - calc(30px / 2));
  }
}

@media screen and (max-width: 768px) {
  .blog-column {
    width: 80%;
    text-align: center;
    margin: auto;
  }
}

.blog-column .blogImage {
  overflow: hidden;
  height: 30vh;
}

.blog-column .blogImage img {
  width: fit-content;
  width: 100%;
  height: 100%;
}

.blogDate {
  color: gray;
}

.blogTitles {
  color: black;
  font-weight: 700 !important;
  margin: 0 !important;
}

.blogTitles .mainTTitle {
  -webkit-line-clamp: 1;
  font-size: 1rem;
  font-weight: bold;
  color: var(--pink-color);
}
.blogTitles .secondTTitle {
  -webkit-line-clamp: 2;
  font-size: 1rem;
}

.blogDescription {
  color: gray;
  -webkit-line-clamp: 3;
}

.blogDescription,
.blogTitles .mainTTitle,
.blogTitles .secondTTitle {
  overflow: hidden;
  text-overflow: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.blogBtn,
.blogBtn:hover {
  background-color: var(--blue-color);
  color: white;
  text-transform: capitalize;
}

/***************** BlogDetails Component Style  ****************/

/* .blogDetailsPage .hero {
  background-image: url("../public/blogsImages/1.png");
} */

.blog-read-btn button {
  background: var(--blue-color) !important;
}

/***************** Contact Component Style  ****************/
.contactPage .hero {
  background-image: url("../src/assets/images/Pages/Contact/contact-hero.jpg");
}

.contactForm {
  background-color: var(--blue-color);
}

.from-title::before {
  content: "GPCO";
  position: absolute;
  color: transparent !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 7.5rem;
  z-index: 0;
  -webkit-text-stroke: 2px var(--pink-color);
  opacity: 0.25;
}

.form-body {
  max-width: 600px;
  gap: 20px;
}

.form-body input:not([type="submit"]),
.form-body textarea {
  width: calc(300px - 10px);
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 2px solid white;
  margin-block: 5px;
  color: white;
  height: 3vh;
  padding: 15px 5px;
}

@media screen and (max-width: 768px) {
  .form-body input:not([type="submit"]),
  .form-body textarea {
    width: 100%;
  }
  .form-body input[type="submit"] {
    width: fit-content;
  }
}

.form-body input::placeholder,
.form-body textarea::placeholder {
  color: white;
  width: 100%;
}
.form-body textarea {
  width: 100%;
  /* height: 15vh; */
  height: auto;
  resize: none;
}

.form-body input[type="submit"] {
  background-color: var(--pink-color);
}

@media screen and (max-width: 768px) {
  .form-body input {
    width: 100%;
  }
}
